import React, { useState } from 'react';
import axios from 'axios';
import 'bulma/css/bulma.min.css';
import AmazonFooter from './util/AmazonFooter';

export default function App() {
  const [url, setUrl] = useState('');
  const [status, setStatus] = useState('');
  const [filename, setFilename] = useState('');
  const [img, setImg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setStatus('');
    setFilename('');
    setImg('');

    try {
      const response = await axios.post('https://rec.recrec.live/api/process', { url });
      const { status, message, filename, img } = response.data;

      setStatus(status);

      if (status === 'completed') {
        setFilename(filename);
        setImg(img);
      } else if (status === 'running') {
        setStatus('running');
      } else if (status === 'accepted') {
        setStatus('accepted');
      } else if (status === 'error') {
        setError(message || 'ダウンロード失敗');
      }
    } catch (error) {
      console.error('Error processing URL:', error);
      setError('予期せぬエラーが発生しました');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    window.location.href = `https://rec.recrec.live/api/download/${filename}`;
  };

  const renderStatusMessage = () => {
    switch (status) {
      case 'accepted':
        return <div className="notification is-info">受け付けました。</div>;
      case 'running':
        return <div className="notification is-info">処理中です。</div>;
      case 'completed':
        return (
          <div>
            <button onClick={handleDownload} className="button is-success mt-2">
              ダウンロード
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <section className="section">
        <div className="container">
          <h1 className="title">アレ</h1>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">URL</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="URLを入力してください"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className={`button is-primary ${isLoading ? 'is-loading' : ''}`}
                  disabled={isLoading}
                >
                  準備開始
                </button>
              </div>
            </div>
          </form>
          {error && (
            <div className="notification is-danger mt-4">
              {error}
            </div>
          )}
          {renderStatusMessage()}
          {img && (
            <div className="mt-4">
              <img src={`https://rec.recrec.live/api/download/${img}`} alt="Thumbnail" />
            </div>
          )}
        </div>
      </section>
      <footer className="footer">
        <AmazonFooter />
      </footer>
    </div>
  );
}