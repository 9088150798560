import React, { useState, useEffect } from 'react';

interface AmazonLink {
    id: number;
    title: string;
    url: string;
}

interface LinksData {
    links: AmazonLink[];
}

const AmazonFooter: React.FC = () => {
    const [links, setLinks] = useState<AmazonLink[]>([]);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await fetch('/a.json');
                const data: LinksData = await response.json();
                setLinks(data.links);
            } catch (error) {
                console.error('Error fetching Amazon links:', error);
            }
        };

        fetchLinks();
    }, []);

    return (
        <footer>
            <div className="content has-text-centered">
                <p>
                    <strong>モチベリンク</strong>
                </p>
                {links.map((link) => (
                    <div className="columns is-centered">
                        <div className="column is-narrow">
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.title}
                            </a>
                        </div>
                    </div>
                ))}
                {/* <div className="column is-narrow">
              <a href="https://www.amazon.jp/hz/wishlist/ls/1CQ0MQ213YO7B?ref_=wl_share" target="_blank" rel="noopener noreferrer">
                ほしいもの
              </a>
            </div> */}
            </div>
        </footer>


    );
};

export default AmazonFooter;